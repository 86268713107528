<template>
  <div class="benefit-form">
    <v-form class="form" ref="form" @submit.prevent="validateForm">
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
              @keydown.enter="focusNext"
              outlined
              dense
              class="label-input"
              :label="$t('benefit.benefitName')"
              :rules="nameRules"
              v-model="items.name"
              :hint="`${server_errors.name}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
            {{ $t("benefit.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      items: {
        name: ""
      },

      server_errors: {
        name: ""
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    saveChange() {
      this.$axios.post(`company/add/benefit`, this.items).then((res) => {
        if (res) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.btnLoading = false;
        this.resetForm();
        this.$router.push({
          name: "benefit.index"
        })
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
          this.btnLoading = false;
        }

      })
    }
  }
}
</script>

<style scoped lang="scss">
.benefit-form {
  width: 100%;
  height: 600px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
</style>
